import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Header from './components/Header';
import './css/base.css';
import './css/header.css';
import './css/match-list.css';
import './css/drawer.css';
import './css/timezone-dropdown.css';
import './css/star-component.css';
import './css/search.css';
import './css/match-details.css';
import './css/policy-pages.css';
import './css/media-queries.css';
import MatchList from './components/MatchList';
import Footer from "./components/Footer";
import moment from "moment";
import 'moment-timezone';
import { Route, Routes, useLocation } from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy";
import CookiePolicy from "./components/CookiePolicy";
import CookieBanner from "./components/CookieBanner";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { pageView } from './utils/gtm';

library.add(faChevronLeft, faChevronRight);

function App() {
    const [period, setPeriod] = useState('today');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [timezone, setTimezone] = useState(moment.tz.guess());
    const [live, setLive] = useState(false);
    const [timeFilter, setTimeFilter] = useState(null);
    const location = useLocation();

    useEffect(() => {
        // Function to determine the date based on the period
        const determineDate = (period) => {
            const today = new Date();
            switch (period) {
                case 'yesterday':
                    return new Date(today.setDate(today.getDate() - 1));
                case 'today':
                    return today;
                case 'tomorrow':
                    return new Date(today.setDate(today.getDate() + 1));
                default:
                    return null;
            }
        };

        if (period === 'now') {
            setLive(true);
        } else {
            const dateToFetch = determineDate(period);

            if (dateToFetch) {
                setLive(false);
                setSelectedDate(dateToFetch);
            }
        }

        setTimezoneCookie(timezone);
    }, [period, timezone]);

    useEffect(() => {
        // Track page views
        pageView(location.pathname + location.search);
    }, [location]);

    const handleTimeFilter = (hours) => {
        setTimeFilter(hours);
    };

    return (
        <div className="App">
            <Header
                setPeriod={setPeriod}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                setTimezone={setTimezone}
                selectedTimezone={timezone}
                setLive={setLive}
                setTimeFilter={handleTimeFilter}
                activeFilter={timeFilter}
            />

            <Routes>
                <Route path="/" element={
                    <MatchList
                        period={period}
                        selectedDate={selectedDate}
                        live={live}
                        timezone={timezone}
                        timeFilter={timeFilter}
                        setTimeFilter={handleTimeFilter}
                    />
                } />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/cookie-policy" element={<CookiePolicy />} />
            </Routes>
            <Footer />
            <CookieBanner />
        </div>
    );
}

function setTimezoneCookie(timezone) {
    Cookies.set('timezone', timezone, { expires: 365 });
}

function getTimezoneFromCookie() {
    return Cookies.get('timezone') || moment.tz.guess(); // Default to the guessed timezone if not set
}


export default App;
