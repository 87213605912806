import React, {useState} from 'react';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as filledStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons';

const StarComponent = ({ leagueId, onStarUpdate }) => {
    leagueId = parseInt(leagueId, 10);

    const [isStarred, setIsStarred] = useState(isLeagueStarred());

    function starLeague() {
        const favouriteLeagues = getStarredLeagues();
        if(!favouriteLeagues.includes(leagueId)) {
            favouriteLeagues.push(leagueId);
            Cookies.set('starred_leagues', favouriteLeagues.join(','), { expires: 365 });
            setIsStarred(true);
            onStarUpdate();
        }
    }

    function unstarLeague() {
        const favouriteLeagues = getStarredLeagues();
        if(favouriteLeagues.includes(leagueId)) {
            favouriteLeagues.splice(favouriteLeagues.indexOf(leagueId), 1);
            Cookies.set('starred_leagues', favouriteLeagues.join(','), { expires: 365 });
            setIsStarred(false);
            onStarUpdate();
        }
    }

    function isLeagueStarred() {
        return getStarredLeagues().includes(leagueId);
    }

    return (
        <span className="starIcon" onClick={isStarred ? unstarLeague : starLeague} title="Favourite">
            <FontAwesomeIcon icon={isStarred ? filledStar : emptyStar} />
        </span>
    );
};

export default StarComponent;

export function getStarredLeagues() {
    return (Cookies.get('starred_leagues') || "").split(',').filter(id => id).map(id => parseInt(id, 10));
}
