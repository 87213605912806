import React, { useEffect, useState, useRef, useCallback } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import MatchRow from './MatchRow';
import Drawer from './Drawer';
import StarComponent, {getStarredLeagues} from './StarComponent';
import {getStarredMatches} from "./MatchStarComponent";
import SearchComponent from './SearchComponent';
import PrevRoundComponent from './PrevRoundComponent';
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar as emptyStar} from "@fortawesome/free-regular-svg-icons";

const NEXT_DAY_HOURS = process.env.REACT_APP_NEXT_DAY_HOURS ? 
    parseInt(process.env.REACT_APP_NEXT_DAY_HOURS) : 8;

function MatchList({ period, selectedDate, live, timezone, timeFilter, setTimeFilter }) {
    const [matches, setMatches] = useState([]);
    const [filteredMatches, setFilteredMatches] = useState([]);
    const [nextDayMatches, setNextDayMatches] = useState([]);
    const isFetching = useRef(false);
    const topLeagueIds = [5,6,808,1,4,2,3,848,78,39,140,135,61,144,88,94,203,207,119,103,271,235,106,345,210,283,286,373,218,357,113,197,179,79,89,141,136,95,120,104,208,40,41,42,358,114,98,99,100,292,293,71,72,262,253,180,66,65,143,45,48,199,529,137,547,817,81,147,550,209,115,97,108,105,212,555,556,90,543,96,519,206,551,654,663,727,557,871,1021,21,803,62,204,63,7,128,129,131,299,15,480,535,268,270,242,342,807,19,24,22,9,531,16,17,200,239,250,130,281,252,18,20,265,31,32,33,34,29,30,12,35,767,810,483,536,713,1043,960];

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedTeams, setSelectedTeams] = useState({ home: '', away: '' });
    const [starredLeagues, setStarredLeagues] = useState(getStarredLeagues());
    const [starredMatchesCookie, setStarredMatchesCookie] = useState(getStarredMatches());
    const [starredMatches, setStarredMatches] = useState([]);
    const [tabToOpen, setTabToOpen] = useState('h2h');

    const closeDrawer = () => setIsDrawerOpen(false);

    const fetchMatches = useCallback((date, live=false) => {
        let query;
        if (live) {
            console.log(`Fetching live matches`);
            query = `?live=all&timezone=${timezone}`
        } else {
            const formattedDate = moment(date).tz(timezone).format('YYYY-MM-DD');
            console.log(`Fetching matches for ${formattedDate}`);
            query = `?date=${formattedDate}&timezone=${timezone}`;
        }

        return axios.get(`https://v3.football.api-sports.io/fixtures${query}`, {
            headers: {
                'x-rapidapi-key': process.env.REACT_APP_API_FOOTBALL_KEY
            }
        })
            .then(response => {
                if (response && response.data) {
                    if (response.data.response && response.data.response.length) {
                        return response.data.response.sort((a, b) => {
                            return new Date(a.fixture.date) - new Date(b.fixture.date);
                        });
                    }
                }
                return [];
            })
            .catch(error => {
                console.error('Error fetching matches:', error);
                return [];
            });
    }, [timezone]);

    useEffect(() => {
        if (isFetching.current) {
            return;
        }

        isFetching.current = true;

        // Only fetch current matches if in live mode
        if (live) {
            fetchMatches(selectedDate, live)
                .then(currentMatches => {
                    setMatches(currentMatches);
                    setNextDayMatches([]);
                    setFilteredMatches(currentMatches);
                    isFetching.current = false;
                });
            return;
        }

        // Normal fetch for non-live mode
        const currentDayPromise = fetchMatches(selectedDate, live);
        const nextDay = moment(selectedDate).add(1, 'day');
        const nextDayPromise = fetchMatches(nextDay, false);

        Promise.all([currentDayPromise, nextDayPromise])
            .then(([currentMatches, nextMatches]) => {
                setMatches(currentMatches);
                setNextDayMatches(nextMatches);
                
                const nextDayStart = moment(selectedDate).add(1, 'day').startOf('day');
                const nextDayLimit = nextDayStart.clone().add(NEXT_DAY_HOURS, 'hours');
                
                const filteredNextDay = nextMatches.filter(match => 
                    moment(match.fixture.date).isBefore(nextDayLimit)
                );
                
                setFilteredMatches([...currentMatches, ...filteredNextDay]);
                isFetching.current = false;
            });
    }, [selectedDate, live, timezone, fetchMatches]);

    const updateStarredLeagues = () => {
        setStarredLeagues(getStarredLeagues());
    };

    const updateStarredMatches = () => {
        setStarredMatchesCookie(getStarredMatches());
    };

    useEffect(() => {
        const newStarredMatches = [...matches, ...nextDayMatches].filter(match =>
            starredMatchesCookie.includes(match.fixture.id)
        );
        setStarredMatches(newStarredMatches);
    }, [matches, nextDayMatches, starredMatchesCookie]);

    const groupedMatches = filteredMatches.reduce((groups, match) => {
        const leagueId = match.league.id;
        const isNextDay = moment(match.fixture.date).isAfter(moment(selectedDate).endOf('day'));
        
        if (!groups[leagueId]) {
            groups[leagueId] = {
                matches: [],
                hasNextDayMatches: false,
                currentDayMatches: [],
                nextDayMatches: []
            };
        }
        
        groups[leagueId].matches.push(match);
        if (isNextDay) {
            groups[leagueId].hasNextDayMatches = true;
            groups[leagueId].nextDayMatches.push(match);
        } else {
            groups[leagueId].currentDayMatches.push(match);
        }
        
        return groups;
    }, {});

    const sortedKeys = [
        ...starredLeagues.filter(id => id in groupedMatches || id.toString() in groupedMatches),
        ...topLeagueIds.filter(id => (id in groupedMatches || id.toString() in groupedMatches) && !starredLeagues.includes(id)),
        ...Object.keys(groupedMatches).filter(id => !topLeagueIds.includes(id.toString()) && !topLeagueIds.includes(parseInt(id)) && !starredLeagues.includes(parseInt(id))),
    ];

    const handleSearch = (searchTerm) => {
        if (!searchTerm) {
            const nextDayStart = moment(selectedDate).add(1, 'day').startOf('day');
            const nextDayLimit = nextDayStart.clone().add(NEXT_DAY_HOURS, 'hours');
            
            const filteredNextDay = nextDayMatches.filter(match => 
                moment(match.fixture.date).isBefore(nextDayLimit)
            );
            setFilteredMatches([...matches, ...filteredNextDay]);
            return;
        }

        const allMatches = [...matches, ...nextDayMatches];
        const filtered = allMatches.filter(match =>
            match.teams.home.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            match.teams.away.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            match.league.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            match.league.country.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setFilteredMatches(filtered);
    };

    useEffect(() => {
        if (timeFilter !== null) {
            const futureTime = moment().add(timeFilter, 'hours');
            const isFilterExtendingToNextDay = futureTime.isAfter(moment().endOf('day'));
            
            // If filter extends to next day, fetch those matches if needed
            if (isFilterExtendingToNextDay) {
                const nextDay = moment(selectedDate).add(1, 'day');
                fetchMatches(nextDay, false).then(nextDayMatches => {
                    // Filter next day matches based on futureTime
                    const filteredNextDay = nextDayMatches.filter(match => {
                        const matchTime = moment(match.fixture.date);
                        return matchTime.isBefore(futureTime);
                    });

                    const combinedMatches = [...matches, ...filteredNextDay];
                    const filtered = combinedMatches.filter(match => {
                        const matchTime = moment(match.fixture.date);
                        return matchTime.isBefore(futureTime) && matchTime.isAfter(moment());
                    });
                    
                    setFilteredMatches(filtered);
                });
            } else {
                // Regular filtering for current day only
                const filtered = matches.filter(match => {
                    const matchTime = moment(match.fixture.date);
                    return matchTime.isBefore(futureTime) && matchTime.isAfter(moment());
                });
                
                setFilteredMatches(filtered);
            }
        } else {
            // When no time filter, show today's matches and next day matches within configured hours
            const nextDayStart = moment(selectedDate).add(1, 'day').startOf('day');
            const nextDayLimit = nextDayStart.clone().add(NEXT_DAY_HOURS, 'hours');
            
            const filteredNextDay = nextDayMatches.filter(match => 
                moment(match.fixture.date).isBefore(nextDayLimit)
            );
            setFilteredMatches([...matches, ...filteredNextDay]);
        }
    }, [timeFilter, matches, nextDayMatches, selectedDate, fetchMatches]);

    return (
        <div className="match-list">
            <SearchComponent onSearch={handleSearch} />
            <div className="match-header">
                <span className="time-column">Time</span>
                <span className="team-name home-team">Home</span>
                <span className="score-column">Score</span>
                <span className="team-name away-team">Away</span>
                <span className="ht-column">HT</span>
                <span className="status-column">Status</span>
            </div>
            {starredMatches.length > 0 && (
                <div key="favourite-matches" className="league-section">
                    <div className="league-header">
                        <div>
                            <span className="starIcon flag-icon">
                                <FontAwesomeIcon icon={emptyStar}/>
                            </span>
                            <span
                                className="league-name">Favourite Matches
                                </span>
                        </div>
                    </div>
                    {starredMatches.map((match) => (
                        <MatchRow key={match.fixture.id}
                                  match={match}
                                  dateFormat="HH:mm"
                                  h2h={false}
                                  timezone={timezone}
                                  setIsDrawerOpen={setIsDrawerOpen}
                                  setSelectedTeams={setSelectedTeams}
                                  onStarUpdate={updateStarredMatches}
                                  round={match.league.round}
                                  season={match.league.season}
                                  leagueId={match.league.id}
                                  setTabToOpen={setTabToOpen}
                        />
                    ))}
                </div>
            )}
            {sortedKeys.map((leagueId) => {
                const league = groupedMatches[leagueId];
                const hasNonStarredMatches = league.matches.some(
                    match => !getStarredMatches().includes(match.fixture.id)
                );

                if (hasNonStarredMatches && league.matches.length > 0) {
                    const firstMatch = league.matches[0];
                    
                    return (
                        <div key={leagueId} className="league-section">
                            <div className="league-header">
                                <div className="league-header-content">
                                    <div>
                                        <span>
                                            {
                                                (firstMatch.league.flag || firstMatch.league.logo) && (
                                                    <img
                                                        src={(firstMatch.league.flag || firstMatch.league.logo).replace('media.api-sports.io', 'media.scoretable.live')}
                                                        alt={firstMatch.league.country}
                                                        className="flag-icon"
                                                    />
                                                )
                                            }
                                        </span>
                                        <span className="league-name">
                                            {firstMatch.league.country} {firstMatch.league.name}
                                        </span>
                                    </div>
                                    <div className="league-actions">
                                        {
                                            firstMatch.league.round && firstMatch.league.season && (
                                                <PrevRoundComponent
                                                    leagueId={leagueId} 
                                                    round={firstMatch.league.round}
                                                    season={firstMatch.league.season}
                                                    setIsDrawerOpen={setIsDrawerOpen}
                                                    setSelectedTeams={setSelectedTeams}
                                                    setTabToOpen={setTabToOpen}
                                                />
                                            )
                                        }
                                        <StarComponent
                                            leagueId={leagueId}
                                            onStarUpdate={updateStarredLeagues}
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            {league.currentDayMatches.map((match) => {
                                if (!getStarredMatches().includes(match.fixture.id)) {
                                    return (
                                        <MatchRow
                                            key={match.fixture.id}
                                            match={match}
                                            dateFormat="HH:mm"
                                            h2h={false}
                                            timezone={timezone}
                                            setIsDrawerOpen={setIsDrawerOpen}
                                            setSelectedTeams={setSelectedTeams}
                                            onStarUpdate={updateStarredMatches}
                                            round={match.league.round}
                                            season={match.league.season}
                                            leagueId={match.league.id}
                                            setTabToOpen={setTabToOpen}
                                        />
                                    );
                                }
                                return null;
                            })}
                            
                            {league.nextDayMatches.length > 0 && (
                                <>
                                    <div className="next-day-badge">
                                        {moment(selectedDate).add(1, 'day').format('DD.MM.YYYY')} Matches
                                    </div>
                                    {league.nextDayMatches.map((match) => {
                                        if (!getStarredMatches().includes(match.fixture.id)) {
                                            return (
                                                <MatchRow
                                                    key={match.fixture.id}
                                                    match={match}
                                                    dateFormat="HH:mm"
                                                    h2h={false}
                                                    timezone={timezone}
                                                    setIsDrawerOpen={setIsDrawerOpen}
                                                    setSelectedTeams={setSelectedTeams}
                                                    onStarUpdate={updateStarredMatches}
                                                    round={match.league.round}
                                                    season={match.league.season}
                                                    leagueId={match.league.id}
                                                    setTabToOpen={setTabToOpen}
                                                />
                                            );
                                        }
                                        return null;
                                    })}
                                </>
                            )}
                        </div>
                    );
                }
                return null;
            })}
            <Drawer
                isDrawerOpen={isDrawerOpen}
                selectedTeams={selectedTeams}
                closeDrawer={closeDrawer}
                tabToOpen={tabToOpen}
            />
        </div>
    );
}

export default MatchList;
