export const GTM_ID = process.env.REACT_APP_GTM_ID;

export const pageView = (url) => {
    if (!GTM_ID) return; // Don't track if no GTM ID (development)
    
    if (!window.dataLayer) {
        window.dataLayer = [];
    }
    window.dataLayer.push({
        event: 'pageview',
        page: url
    });
};

export const pushEvent = (event, data = {}) => {
    if (!GTM_ID) return; // Don't track if no GTM ID (development)
    
    if (!window.dataLayer) {
        window.dataLayer = [];
    }
    window.dataLayer.push({
        event,
        ...data
    });
}; 